.wrapper-div {
    position: relative;
}

.bgImage {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    min-height: 100%;
    min-width: 1920px;
    width: 100%;
    height: auto;
}

.x-callout {
    position: absolute;
    position: fixed;
    top: 50%;
    left: 30%;
    width: 100px;
    height: 100px;

}

@media screen and (max-width: 1920px) {
    .bgImage {
        left: 50%;
        margin-left: -960px;
    }

    .x-callout {
        top: 550px;
        left: 633px;
    }
}