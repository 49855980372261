@keyframes float {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-3px);
	}

	100% {
		transform: translatey(0px);
	}
}

@keyframes shadowFloat {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.7;
	}

	100% {
		opacity: 1;
	}
}

.floating-shadow {
	animation: shadowFloat 4s ease-in-out infinite;
}

.floating-element {
	animation: float 4s ease-in-out infinite;
}

.carousel-images-wrapper {
	position: absolute;

}

.carousel-shadow-img {

	position: absolute;
	min-width: 100%;
	min-height: 100%;
	float: none;
	top: 0px;
	padding: none;
	position: fixed;
	background: transparent;
	border-bottom: 1px solid transparent;
	z-index: 2;
}

.carousel-product-img {
	position: relative;
	/*
	position: absolute;
	float: none;
	top: 0;
	
	
	min-width: 100%;
	min-height: 100%;

	padding: none;
	position: fixed;*/
	
	background: transparent;
	border-bottom: 1px solid transparent;
	
	z-index: 3;
}

.bg-image-div {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	z-index: -5;
	display: flex;
	justify-content: center;
}