.inactive-color {
    opacity: 0.95;
    background-color: var(--main-handtmann-color-inactive) !important;
}

.inactive-color:hover {
    background-color: var(--main-handtmann-color) !important;
}

.route-back-button {
    position: absolute;
    top: 93%;
    left: max(3.5vw, 49px);
}

.menu-back-button {
    position: relative;
    left: 0px;
}


.back-button-container {
    position: relative;
    width: 90px;
    height: 34px;
    cursor: pointer;
    z-index: 5;
}

.back-button-wrapper {
    background-color: var(--main-handtmann-color);
}

.back-button-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    font-size: 18.24px;
    font-weight: 600;
    height: 34px; /*max(1.78vw, 34px);*/
}
