.mobile-background-responsive-container {
    position: absolute;
    width: 100%;
    height: auto;
}

.mobile-background-responsive {
    top: 20px;
    width: 100%;
    height: 70vh;
    position: relative;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
}







.mobile-overview-selected-product {
    /*
    --theX: calc(150% + 50vw);
    --theY: 20%;
    --theScale: 1.5;
    transform-origin: var(--theX) var(--theY);
    transform: scale(var(--theScale));
    */
}

@keyframes smoothFocus {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

@keyframes smoothFocusP {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

@keyframes smoothFocusT {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

@keyframes smoothFocusS {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

@keyframes smoothFocusA {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

@keyframes smoothFocusL {
    from {
        background-position-x: var(--lastXPos);
        background-position-y: var(--lastYPos);
    }

    to {
        background-position-x: var(--xValue);
        background-position-y: var(--yValue);
    }
}

.mobile-landingpage-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/webm/stills/Totale-Still.jpg") no-repeat;
    z-index: -9;
}

.mobile-landingpage-background-sProducts {
    background-position: 77% 30%;
    --xValue: 77%;
    --yValue: 30%;
    animation: smoothFocusP 1s;

}

.mobile-landingpage-background-sTechnology {
    background-position: 52.5% 30%;
    --xValue: 52.5%;
    --yValue: 30%;
    animation: smoothFocusT 1s;
}

.mobile-landingpage-background-sAboutus {
    background-position: 73.5% 95.5%;
    --xValue: 73.5%;
    --yValue: 95.5%;
    animation: smoothFocusA 1s;
}

.mobile-landingpage-background-sSustainability {
    background-position: 18.5% 56%;
    --xValue: 18.5%;
    --yValue: 56%;
    animation: smoothFocusS 1s;
}

.mobile-landingpage-background-sLocations {
    background-position: 30.5% 80%;
    --xValue: 30.5%;
    --yValue: 80%;
    animation: smoothFocusL 1s;
}

.mobile-page-background {
    /* background url is set in-line css */
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat;
    z-index: -9;
}

.mobile-page-background-wrapper {}

.mobile-page-background-landscape {
    /* background url is set in-line css
    width: auto;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    transform: translateY(-14vh);
    z-index: -9;
     */
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat;
    background-position: 50% 95%;
    z-index: -9;
}

.mobile-page-background-landscapeNT {
    /* background url is set in-line css */
    width: auto;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -9;
}