.backdrop-modal-video-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    /*display: none;*/
    z-index: 9995;

}

.backdrop-modal-video-content-wrapper {
    position: relative;
    width: 65%;
    height: 80%;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(255, 255, 255, 0.95);
    z-index: 9996;
    overflow-y: auto;

    text-align: left;
}
.backdrop-modal-video-content-wrapper-simple {
    position: relative;
    width: 65%;
    height: 80%;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(255, 255, 255, 0.95);
    z-index: 9996;
    overflow-y: auto;

    text-align: left;
}

.backdrop-modal-video-content {
    color: black;
    /* top right bottom left */
    padding: 5% 15% 5% 15%;
    z-index: 9997;
}

.backdrop-modal-video-video {
    width: 100%;
    height: auto;
}
#video-overlay-video {
    width: 100%;
}

.backdrop-modal-video-header-banner {
    text-align: right;
    background-color: var(--main-handtmann-color);
    margin-bottom: 1%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.backdrop-modal-video-footer-banner {
    text-align: right;
    background-color: var(--main-handtmann-color);
    margin-top: 6%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.backdrop-modal-video-close-button {
    font-weight: bold;
    padding-right: 3%;
    cursor: pointer;
    color: white;
}

.backdrop-modal-video-heading {
    margin-bottom: 4%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}


.active-modal {
    display: block;
    z-index: 9995;
}