.product-selection {
    position: absolute;
    top: 65px;
    bottom: 0;
    overflow: auto;
    width: 100%;
    padding: 25px 0;
}

.product-selection.product-selection--desktop {
    width: 60%;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 0 25px 0;
}

.product-selection .category-label {
    color: black;
    font-weight: bold;
    font-size: calc(22pt + 0.2vw);
    text-align: center;
}

.product-selection .products-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-selection .products-list.products-list--grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.product-selection .products-list .products-list-item {
    background-color: rgba(255, 255, 255, 0.7);
    width: 80%;
    max-width: 400px;
    min-width: 200px;
    height: auto;
    margin: 1%;
}

.product-selection .products-list.products-list--grid .products-list-item {
    width: 37%;
    max-width: none;
}

.product-selection .products-list-item .product-image-wrapper {
    position: relative;
    width: 100%;
    height: auto;
}

.product-selection .products-list-item .product-image {
    position: relative;
    width: 100%;
    aspect-ratio: 1;

    min-width: 199px;
    max-width: 450px;
    cursor: pointer;
    margin-bottom: 5px;
    --scaler: calc(40vw / 3.84);
    --scalerh: calc(5vh / 3.84);
    width: max(var(--scaler), var(--scalerh));
}

.product-selection .products-list-item .product-footer {
    background-color: var(--main-handtmann-color);
    position: relative;
}

.product-selection .products-list-item .product-label {
    color: white !important;
    font-size: 18px !important;

    font-weight: bold;
    font-size: calc(4pt + 0.7vw);
    color: var(--main-handtmann-color);
    cursor: pointer;
}
