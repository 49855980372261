
.overview-heading {
    font-size: xx-large;
    font-weight: 500;
    position: absolute;
    left: 25px;
    top: 65px;
}

.overview-carousel-wrapper {
    width: 100%;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overview-carousel-div {
    position: relative;
}

.overview-carousel-content {
    padding-left: 3px;
    padding-right: 3px;
    width: 96%;
}

.overview-carousel-item-border {
    width: 44px;
    height: 44px;
    /*border: 1px solid green;*/
}

.selected-o-item {
    background-color: #E65F0F;
}