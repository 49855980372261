@keyframes float {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-3px);
	}

	100% {
		transform: translatey(0px);
	}
}

@keyframes shadowFloat {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.45;
	}

	100% {
		opacity: 1;
	}
}

.floating-shadow {
	animation: shadowFloat 4s ease-in-out infinite;
}

.floating-element {
	animation: float 4s ease-in-out infinite;
}


.mobile-products-page-background {
	position: absolute;
	top: 20px;
	height: 70vh;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 125vh;
}

.mobile-products-page-background-landscape {
	/*
	top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat;
	*/
	position: absolute;
	width: 100%;
	height: 100%;
	/*background: no-repeat;*/
	background-position: 50% 95%;
	z-index: -7;
}

.mobile-fullscreen-video-container {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9999;
	pointer-events: none;
}






.mobile-products-product-item-container {
	position: absolute;
	width: 100%;
	height: auto;
}

.mobile-products-product-item {
	position: absolute;
	top: 20px;
	width: 100%;
	height: 70vh;
	background-size: cover !important;
	/*

	background-position-x: center;
	background-position-y: center;
	*/
}
