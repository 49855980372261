@font-face {
  font-family: myHandtmann;
  src: url(assets/fonts/DINPro.otf);
  font-weight: normal;
}

@font-face {
  font-family: myHandtmann;
  src: url(assets/fonts/DINPro-Bold.otf);
  font-weight: bold;
}

:root {
  font-family: 'myHandtmann', -apple-system, BlinkMacSystemFont, 'Comic Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}
* {
  font-family: 'myHandtmann', -apple-system, BlinkMacSystemFont, 'Comic Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

body, html {
  overflow: hidden;
  margin: 0;
  font-family: 'myHandtmann', -apple-system, BlinkMacSystemFont, 'Comic Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'myHandtmann', source-code-pro, Menlo, Monaco, Consolas, 'DINPro', 'Courier New',
    monospace !important;
}


.hide-scrollbar {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
