a {
    text-decoration: none;
}

:root {
    --heading-background-color: rgba(255, 255, 255, 0.7);
}

/* container to move all buttons around */
.hj-products-callout-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 65px;
    bottom: 0;
    padding: 50px 32px 0 32px;
    overflow: auto;
}

.hj-products-callout-container.hj-products-callout-container--desktop {
    width: 400px;
    top: max(28%, 302px);
    /*left: 55%*/
}

/* wrapper for the button itself */
.hj-callout-products-wrapper {
    z-index: 5;
    background-color: var(--main-handtmann-color);
    height: 34px;
    /*max(1.78vw, 34px);*/
    width: 100%;
    margin-bottom: 5%;
}

/* content of the button, text in this case */
.hj-callout-products-content {
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    /*max(1.78vw, 34px);*/
    position: relative;
    color: white;
    font-size: 18.24px;
    font-weight: bold;
}



/* ********************* TECHNOLOGY ********************************/

.hj-technology-heading {
    font-weight: bold;
    font-size: 25px;
    /*1.3vw;*/
    margin-bottom: 3%;
}

.hj-technology-callout-container {
    position: absolute;
    width: 100%;
    max-height: calc(100vh - 85px);
    left: 0;
    top: 85px;
    bottom: 0;
    padding: 50px 32px 0 32px;
    overflow: auto;
}

.hj-technology-callout-container.hj-technology-callout-container--desktop {
    width: 400px;
    /*18%;*/
    top: max(23%, 249px);
    left: 55%;
    overflow: auto;
    padding: 0;
}

/* wrapper for the button itself */
.hj-callout-technology-wrapper {
    z-index: 5;
    background-color: var(--main-handtmann-color);
    height: 34px;
    /*max(1.78vw, 34px);*/
    /*100%;*/
    margin-bottom: 5%;
    cursor: pointer;
}

.hj-technology-callout-container--desktop .hj-callout-technology-wrapper {
    width: 400px;
}

/* content of the button, text in this case */
.hj-callout-technology-content {
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    /*max(1.78vw, 34px);*/
    position: relative;
    color: white;
    font-size: 18.24px;
    /*0.95vw;*/
    font-weight: bold;
}


/* ******************* LANDINGPAGE ********************* */

.hj-callout-landingpage-container {
    width: 10vw;
}

/* wrapper for the button itself */
.hj-callout-landingpage-wrapper {
    z-index: 5;
    background-color: var(--main-handtmann-color);
    height: max(1.78vw, 34px);
    width: 100%;
    margin-bottom: 5%;
}

/* content of the button, text in this case */
.hj-callout-landingpage-content {
    text-align: center;
    vertical-align: middle;
    line-height: max(1.78vw, 34px);
    position: relative;
    color: white;
    font-size: 0.95vw;
    font-weight: bold;
}


/* ************** TECHNOLGY ROOMS ****************/
.technology-room-menu-container {
    position: absolute;
    bottom: 5%;
    left: 3%;
    width: 325px;
    /*18vw;*/
}

.hj-technology-room-heading {
    background-color: var(--heading-background-color);
    font-weight: bold;
    font-size: 25px;
    /*1.3vw;*/
    margin-bottom: 3%;
}

/* wrapper for the button itself */
.hj-callout-technology-room-wrapper {
    z-index: 5;
    background-color: var(--main-handtmann-color);
    height: 34px;
    /* max(1.78vw, 34px);*/
    width: 325px;
    margin-bottom: 5%;
}

/* content of the button, text in this case */
.hj-callout-technology-room-content {
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    /*max(1.78vw, 34px);*/
    position: relative;
    color: white;
    font-size: 18.24px;
    /*0.95vw;*/
    font-weight: bold;
}

.inactive-color {
    opacity: 0.95;
    background-color: var(--main-handtmann-color-inactive) !important;
}

.inactive-color:hover {
    background-color: var(--main-handtmann-color) !important;
}


/* ************** PRODUCTS ROOMS ********* */
.products-room-menu-container {
    position: absolute;
    bottom: 5%;
    left: 3%;
    width: 450px;
    /*22vw;*/
}

.hj-products-room-heading {
    background-color: var(--heading-background-color);
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 3%;
}

/* wrapper for the button itself */
.hj-callout-products-room-wrapper {
    z-index: 5;
    background-color: var(--main-handtmann-color);
    height: 34px;
    /*max(1.78vw, 34px);*/
    width: 450px;
    margin-bottom: 5%;
}

/* content of the button, text in this case */
.hj-callout-products-room-content {
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    /*max(1.78vw, 34px);*/
    position: relative;
    color: white;
    font-size: 18.24px;
    /*0.95vw;*/
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}


/* ****************** POPUP BOX ****************** */
/* The actual popup (appears on top) */
.popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: var(--main-handtmann-color-inactive);
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
}

/* Popup arrow */
.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--main-handtmann-color-inactive) transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
