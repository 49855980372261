.mobile-products-detailcontainer {
    position: absolute;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 1);
    height: 40%;
    width: 100%;
    /*max-height: 333px;*/
    top: 60%;
    overflow-y: auto;
}

.mobile-products-detail-container-content {
    text-align: left;
    font-size: large;
    font-weight: bold;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 25px;
    font-weight: 400;
}

.mobile-products-detail-container-heading {
    margin-top: 15px;
    text-align: left;
    margin-left: 16px;
    font-size: xx-large;
}

.mobile-products-detail-container-backbutton {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 16px;
}

.mobile-products-detail-description {
    margin-right: 16px;
    font-size: small;
    font-weight: 400;
    margin-bottom: 5px;
}

.mobile-products-detail-facts-heading {
    font-size: small;
}

.mobile-products-detail-facts {
    font-size: small;
}
.mobile-products-detail-contents {
    font-size: small;
}

.mobile-products-detail-links {
    font-size: small;
    color: var(--main-handtmann-color);
    text-decoration: none;
    margin-top: 15px;

}

.mobile-products-detail-container-link>a:link,
.mobile-products-detail-container-link>a:hover,
.mobile-products-detail-container-link>a:visited,
.mobile-products-detail-container-link>a:active {
    /*font-size: small;*/
    text-decoration: none;
    color: var(--main-handtmann-color);
}