.redesign-landingpage-buttons-containers {
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
}

.redesign-landingpage-products-button-wrapper {
    position: absolute;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;


}

.redesign-landingpage-products-button {
    background-color: var(--main-handtmann-color);
    color: white;
    width: max(165px, 10vw);
    text-align: center;
    vertical-align: middle;
}

.redesign-button {
    position: relative;
    text-align: center;
    vertical-align: middle;
    font-size: max(18.25px, 0.95vw);
    font-weight: bold;
    line-height: max(1.78vw, 34px);
}


.products {
    position: fixed;
    /*
    --varL: max(1320px, calc(1320 * (100vh / 1080)));
    --varT: max(180px, calc(180 * (100vw / 1920)));
    left: max(68.75%, var(--varL));
    top: max(16.6667%, var(--varT));
    */
    --varL: max(1110px, calc(1110 * (100vh / 1080)));
    --varT: max(180px, calc(180 * (100vw / 1920)));
    left: max(57.812%, var(--varL));
    top: max(16.6667%, var(--varT));
}

.callout-image-products {
    --varS: calc(50vw / 3.84);
    --varSh: calc(92vh / 3.84);
    min-width: 240.5px;
    width: max(var(--varS), var(--varSh));

}



.technology {
    position: fixed;
    /*
    --varL: max(960px, calc(960 * (100vh / 1080)));
    --varT: max(330px, calc(330 * (100vw / 1920)));
    left: max(50%, var(--varL));
    top: max(30.555%, var(--varT));
    */
    --varL: max(710px, calc(710 * (100vh / 1080)));
    --varT: max(255px, calc(255 * (100vw / 1920)));
    left: max(36.979%, var(--varL));
    top: max(23.148%, var(--varT));
}

.callout-image-technology {
    --varS: calc(50vw / 3.84);
    --varSh: calc(91vh / 3.84);
    min-width: 240.5px;
    width: max(var(--varS), var(--varSh));

}

.aboutus {
    position: fixed;
    /*
    --varL: max(1275px, calc(1275 * (100vh / 1080)));
    --varT: max(840px, calc(840 * (100vw / 1920)));
    left: max(66.40625%, var(--varL));
    top: max(77.77777%, var(--varT));
    */
    --varL: max(1025px, calc(1025 * (100vh / 1080)));
    --varT: max(870px, calc(870 * (100vw / 1920)));

    left: max(53.385%, var(--varL));
    top: max(80.555%, var(--varT));
}

.callout-image-aboutus {
    --varS: calc(46.5vw / 3.84);
    --varSh: calc(88.5vh / 3.84);
    min-width: 235.5px;
    width: max(var(--varS), var(--varSh));

}

.sustainability {
    position: fixed;
    /*
    --varL: max(480px, calc(480 * (100vh / 1080)));
    --varT: max(410px, calc(410 * (100vw / 1920)));

    left: max(25%, var(--varL));
    top: max(21.354%, var(--varT));
    */
    --varL: max(480px, calc(480 * (100vh / 1080)));
    --varT: max(410px, calc(410 * (100vw / 1920)));

    left: max(25%, var(--varL));
    top: max(37.962%, var(--varT));
}

.callout-image-sustainability {
    --varS: calc(54vw / 3.84);
    --varSh: calc(92vh / 3.84);
    min-width: 245.5px;
    width: max(var(--varS), var(--varSh));

}

.locations {
    position: fixed;
    /*
    --varL: max(675px, calc(675 * (100vh / 1080)));
    --varT: max(690px, calc(690 * (100vw / 1920)));
    left: max(35.156%, var(--varL));
    top: max(63.888%, var(--varT));
    */
    --varL: max(675px, calc(675 * (100vh / 1080)));
    --varT: max(720px, calc(720 * (100vw / 1920)));
    left: max(35.156%, var(--varL));
    top: max(66.66667%, var(--varT));
}

.callout-image-locations {
    --varS: calc(48.5vw / 3.84);
    --varSh: calc(90vh / 3.84);
    min-width: 235.5px;
    width: max(var(--varS), var(--varSh));

}

/*
Idee: vorher das Seitenverhältnis berechnen? Dann könnte ich den prozentualen wert abhängig vom verhältnis irgendwie berechnen über ne fancy formel?
*/
.productsvw {
    position: fixed;

    --varL: max(1350px, calc(1350 * (100vh / 1080)));
    left: max(70.312%, var(--varL));

    --varT: max(250px, calc(250 * (100vw / 1920)));
    top: max(23.148vh, var(--varT));
}












.engines {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(288px, calc(288 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(26.6667%, var(--varT));
}

.callout-image-engines {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.transmission {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(423px, calc(423 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(39.1667%, var(--varT));
}

.callout-image-transmission {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}


.body-chassis-steering {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(558px, calc(558 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(51.6667%, var(--varT));
}

.callout-image-bcs {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.elmobility {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(693px, calc(693 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(64.1667%, var(--varT));
}

.callout-image-elmobility {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}










/* TECHNOLOGY/COMPETENCE CALLOUTS: */

.assembly-systems-engineering-heading {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(125px, calc(125 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(11.574%, var(--varT));
}

.metal-casting-heading {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(500px, calc(500 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(43.981%, var(--varT));
}

.assembly-systems-engineering {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(200px, calc(200 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(2.8333%, var(--varT));
}

.engineering {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(325px, calc(325 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(3.407%, var(--varT));
}

.high-pressure-die-casting {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(575px, calc(575 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(43.981%, var(--varT));
}

.gravity-die-casting {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(700px, calc(700 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(55.555%, var(--varT));
}

.assembly {
    position: fixed;
    --varL: max(1050px, calc(1050 * (100vh / 1080)));
    --varT: max(825px, calc(825 * (100vw / 1920)));
    left: max(54.687%, var(--varL));
    top: max(67.129%, var(--varT));
}



.callout-image-ase {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.callout-image-engineering {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.callout-image-hpdc {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.callout-image-gdc {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}

.callout-image-asm {
    --varS: calc(82vw / 3.84);
    --varSh: calc(1vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 370.5px;
    width: max(var(--varS), var(--varSh));
}



























.tianjin {
    position: fixed;
    --varL: max(1265px, calc(1265 * (100vh / 1080)));
    --varT: max(231px, calc(231 * (100vw / 1920)));
    left: max(65.885%, var(--varL));
    top: max(21.3888%, var(--varT));
}

.callout-image-tianjin {
    --varS: calc(55vw / 3.84);
    --varSh: calc(95vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 265px;
    width: max(var(--varS), var(--varSh));
}


.kechnec {
    position: fixed;
    --varL: max(952px, calc(952 * (100vh / 1080)));
    --varT: max(246px, calc(246 * (100vw / 1920)));
    left: max(49.583%, var(--varL));
    top: max(22.777%, var(--varT));
}

.callout-image-kechnec {
    --varS: calc(55vw / 3.84);
    --varSh: calc(95vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 265px;
    width: max(var(--varS), var(--varSh));
}


.texas {
    position: fixed;
    --varL: max(205px, calc(205 * (100vh / 1080)));
    --varT: max(395px, calc(395 * (100vw / 1920)));
    left: max(10.677%, var(--varL));
    top: max(36.577%, var(--varT));
}

.callout-image-texas {
    --varS: calc(53vw / 3.84);
    --varSh: calc(95vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 265px;
    width: max(var(--varS), var(--varSh));
}


.biberach {
    position: fixed;
    --varL: max(622px, calc(622 * (100vh / 1080)));
    --varT: max(242px, calc(242 * (100vw / 1920)));
    left: max(32.395%, var(--varL));
    top: max(22.407%, var(--varT));
}

.callout-image-biberach {
    --varS: calc(53vw / 3.84);
    --varSh: calc(95vh / 3.84);
    /*147vh / 3.84);*/
    min-width: 265px;
    width: max(var(--varS), var(--varSh));
}