:root {
  --main-handtmann-color: #E65F0F;
  --main-handtmann-color-inactive: #f99156;
  --lastXPos: 77%;
  --lastYPos: 30%;
  font-family: 'myHandtmann' !important;
}

.App {
  text-align: center;
}

div {
font-family: 'myHandtmann' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.App-body {
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.globalContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: -5;

}

.scroll-div {
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

.bg-video-container-redesign {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.bg-video-div-redesign {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -5;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.bg-video-div-redesign > div {
  position: relative;
}

.bg-video-div-redesign video:not(:first-child),
.bg-video-div-redesign img:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

#background-video-redesign {
  width: 100vw;
  height: auto;
}

.bg-video-div {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid transparent;

  /* RESPONSIVE REDESIGN
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: contain;
  width: 100%;
  height: 100%;
  */
}

#background-video {

  min-width: 100%;
  min-height: 100%;
  float: none;
  top: 0;
  padding: none;
  position: fixed;
  background: transparent;
  border-bottom: 1px solid transparent;
  z-index: 5;
  /* REDESIGN REPSONSIVE
  width: auto;
  height: 100%;
  */
}

#transition-video {
  /*
  min-width: 100%;
  min-height: 100%;
  float: none;
  top: 0;
  padding: none;
  position: fixed;
  */
  background: transparent;
  border-bottom: 1px solid transparent;
}

.background-layer-still {
  /*
  min-width: 100%;
  min-height: 100%;
  float: none;
  top: 0;
  padding: none;
  position: fixed;
  */
  background: transparent;
  border-bottom: 1px solid transparent;
  z-index: 4;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }


}

.inactiveCarousel-image {
  animation: fadeOut 1s;
}

.activeCarousel-image {
  animation: fadeIn 1s;
}

.fade-in-image {
  animation: fadeIn 1.25s;
}

.fade-in-movie {
  animation: fadeIn 2.5s;
}

.fade-in-transition-movie {
  animation: fadeIn 0.25s;
}

.fade-in-button {
  animation: fadeIn 2s;
}

/* BUTTONS **/


.callout-text {
  position: absolute;
  font-size: 28px;
  font-weight: 600;

  text-align: right;
  overflow: visible;
  /*transform: translate(-155px, -105px);
*/
}

.callout-text-products {
  position: absolute;
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  overflow: visible;
  width: 425px;
}

.callout-text-locations {
  position: absolute;
  font-size: 32px;
  font-weight: 600;
  text-align: right;
  overflow: visible;
  width: 225px;
}

.callout-text-technology {
  position: absolute;
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  overflow: visible;
  width: 625px;
}

.callout-button-container {
  z-index: 5;
  cursor: pointer;
  color: black;
  position: absolute;

}

@media screen and (min-width: 2000px) {
  /*
  .callout-button-container {
    transform: scale(1.25);
  }*/

  #backButton {
    /*transform: scale(1.25);*/
  }
}

@media screen and (min-width: 3000px) {

  /*
  .callout-button-container {
    transform: scale(1.55);
  }*/
  #backButton {
    /*transform: scale(1.55);*/
  }
}

.callout-text-container {
  z-index: 5;
  cursor: pointer;
  color: black;
  position: relative;
}

.productsCalloutContainer {
  display: block;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  color: black;
  overflow: visible;
  width: auto;
}



.calloutLinesProducts {
  height: 60px;
  width: 200px;
  border-top: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}


.calloutLines-products-technology {
  width: 200px;
  height: 60px;
  border-top: 8px solid var(--main-handtmann-color);
  border-right: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);

  /*
  --varW: max(200px, calc(200 * (100vh / 1920)));
  --varH: max(200px, calc(200 * (100vw / 1080)));
  zoom: var(--varH);
  transform: translateX(-100px);
*/

}

.calloutLines-sustainability {
  height: 60px;
  width: 200px;
  border-top: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}

.calloutLines-locations {
  height: 60px;
  width: 200px;
  border-bottom: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
  color: white;
}

.calloutLines-aboutus {
  height: 60px;
  width: 200px;
  border-bottom: 8px solid var(--main-handtmann-color);
  border-right: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}

.kings-cl {
  height: 60px;
  width: 200px;
  border-top: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}

.tianjin-cl {
  height: 60px;
  width: 200px;
  border-top: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}

.kechnec-cl {
  height: 60px;
  width: 200px;
  border-bottom: 8px solid var(--main-handtmann-color);
  border-left: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
}

.biberach-cl {
  height: 60px;
  width: 200px;
  border-top: 8px solid var(--main-handtmann-color);
  border-right: 10px solid var(--main-handtmann-color);
  -moz-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
}

.buttonContainer {
  display: block;
  top: 25%;
  left: 48%;
  width: 100px;
  height: 28px;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  color: black;
}



.techButton {
  display: block;
  top: 35%;
  left: 58%;
  width: 100px;
  height: 28px;
  position: absolute;
  z-index: 5;
  background: orange;
  cursor: pointer;
  color: white;
}

.productsButton {
  display: block;
  top: 15%;
  left: 18%;
  width: 100px;
  height: 28px;
  position: absolute;
  z-index: 5;
  background: orange;
  cursor: pointer;
  color: white;
}

.landingpage-callouts {
  position: absolute;
  overflow: hidden;
}
