/*.mobile-landingpage-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/webm/stills/Totale-Still.jpg") no-repeat 77% 30%;
    z-index: 999;
}
/* obsolete */

.mobile-overview-heading {
    width: 75%;
    background-color: rgba(222, 222, 222, 0.5);
    font-size: xx-large;
    font-weight: 500;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 85%; /* beware of the planned navbar */
    color: black;
}

.mobile-overview-link {
    color: black;
    
}