#loading-overlay {
  position: absolute;
  z-index: 901;
  background-color: lightgray;
  width: 100%;
  height: 100%;
}

#loading-screen {
  z-index: 902;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -160px;
  margin-left: -160px;
  border: 36px solid #f3f3f3;
  border-radius: 50%;
  border-top: 36px solid var(--main-handtmann-color);
  width: 320px;
  height: 320px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}