.mobile-navbar-container {
    z-index: 9979;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background-color: white;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.mobile-navbar-menu-container {
    z-index: 9980;
    display: none;
    margin-left: 0px !important;
    position: absolute;
    background-color: white;
    width: 100vw;
    top: 64px;
    left: 0;
    height: calc(100vh - 65px);
    overflow-y: auto;
}

.show-menu {
    display: block !important;
}

.mobile-navbar-menu-wrapper {
    text-align: left;
    position: relative;
    margin: 5px 5px 5px 5px;
}
.mobile-navbar-menu-heading {
    font-size: large;
    font-weight: bold;
    cursor: default;
}

.mobile-navbar-menu-item-button {
    cursor: default;
}
.mobile-navbar-menu-item-button:hover {
    cursor: default;
    background-color: rgba(0,0,0, 0.1);
}
.mobile-navbar-menu-item {
    font-size: large;
    font-weight: normal;
}


/* ****** CSS BURGER MENU BUTTON ******* */

.bm-container {
    display: inline-block;
    cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.bm-change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.bm-change .bar2 {
    opacity: 0;
}

.bm-change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}
