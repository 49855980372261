/* TODO cleanup file */
.p-infobox-wrapper {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 36%;
    max-width: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}
.sx-infobox-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 45%;
    max-width: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.s-infobox-spacer {
    height: 20%;
}


.p-infobox-wrapper.hide {
    transform: translate(105%);

}

.t-infobox-wrapper.hide {
    transform: translate(105%);
}

.p-infobox-content {
    position: relative;
    top: 0px;
    height: 100%;
    margin-left: 8%;
    text-align: left;
}

.t-infobox-content {
    position: relative;
    top: 120px;
    height: 100%;
    margin-left: 8%;
    text-align: left;
}

.p-infobox-text {
    font-family: myHandtmann, Helvetica, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    font-size: max(16px, calc(3pt + 0.5vw));
    /*0.91em;*/
}

.s-infobox-text {
    font-family: myHandtmann, Helvetica, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    font-size: 1.1em;
    max-width: 90%;
}

.p-infobox-heading {
    font-size: calc(14pt + 0.15vw);
    margin-bottom: 3%;
    margin-top: max(40px, 10%);
}

.s-infobox-heading {
    font-size: larger;
    margin-bottom: 5%;
    margin-top: 3%;
}

@media screen and (min-width: 2000px) {
    .s-infobox-heading {
        font-size: 26pt;
        margin-bottom: 5%;
    }
}

@media screen and (min-width: 3000px) {
    .s-infobox-heading {
        font-size: 30pt;
        margin-bottom: 5%;
    }
}

.p-infobox-subheading {
    font-size: calc(20pt + 0.3vw);
    margin-bottom: 5%;
}

.p-infobox-description {
    margin-bottom: 10%;
    font-size: max(16px, calc(3pt + 0.5vw));
    /*0.91em;*/
}

.p-infobox-facts-heading {
    padding-bottom: 3%;
}

.p-infobox-facts-list {
    list-style: none;
    list-style-position: 0;
    margin-bottom: 2%;
}

.p-infobox-content-list {
    list-style: none;
    list-style-position: 0;
    margin-bottom: 35px;
    cursor: pointer;
    color: var(--main-handtmann-color);
}

.p-infobox-fact-element {
    padding: 1%;
    padding-left: 0%;
}

.p-infobox-links-section {
    position: relative;
    color: var(--main-handtmann-color);
}

.p-infobox-link {
    text-decoration: none;
    color: var(--main-handtmann-color);
}


a:hover {
    color: var(--main-handtmann-color);
}

.p-infobox-content-list>div>a {
    text-decoration: none;
    color: var(--main-handtmann-color);
}


.s-dot {
    height: 12px;
    width: 12px;
    background-color: var(--main-handtmann-color);
    border-radius: 50%;
    display: inline-block;
}

.s-infobox-desc-text {
    font-family: myHandtmann, Helvetica, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    font-size: 1.1em;
    padding: 0 4.5%;
    text-align: left;
    font-weight: bold;
}

.t-infobox-content-timeline {
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    height: 32%;
    text-align: left;
    /*overflow: auto;*/
    overflow: hidden;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.t-infobox-content-timeline::-webkit-scrollbar {
    display: none;
}


.t-infobox-content-timeline table {
    width: 100%;
    color: var(--main-handtmann-color);
    font-size: 16pt;
    font-weight: bold;
    margin-left: -15%;
}

.timeline-active {
    color: black !important;
    transition: color 0.25s ease ;
}
